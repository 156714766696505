<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    :options="state.statusLists"
                    allow-clear
                    style="width:100%;"
                    placeholder="Pilih Status"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Master-Data-Sample"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Aktif</ATag>
                    <ATag v-else color="grey">Tidak Aktif</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Mapping Toko">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnMappingList(record)">
                                <i class="fe fe-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- mapping list -->
        <MappingList
            v-if="visibleMappingListModal"
            v-model:visible="visibleMappingListModal"
            v-model:item="visibleMappingListItemModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import MappingList from './MappingList'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        DownloadExcel,
        Form,
        MappingList,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nama',
                    dataIndex: 'name',
                },
                {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                },
                {
                    title: 'Start Date',
                    dataIndex: 'begin_date',
                },
                {
                    title: 'End Date',
                    dataIndex: 'end_date',
                },
                {
                    title: 'Status',
                    dataIndex: 'active',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/data-sample',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                status: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                { value: 1, label: 'Aktif' },
                { value: 0, label: 'Tidak Aktif' },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle mapping list
        const visibleMappingListModal = ref(false);
        const visibleMappingListItemModal = ref(null);

        const btnMappingList = (item = null) => {
            visibleMappingListModal.value = true
            visibleMappingListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            visibleFormModal,
            visibleFormItemModal,
            btnForm, // create or update
            fetchDataList,
            state,
            handleTableChange,
            errorMessage,
            queryParams,
            btnMappingList,
            visibleMappingListModal,
            visibleMappingListItemModal,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
